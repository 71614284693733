.navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
      z-index: 1;
    }
  
    .container-navbar {
      width: 100%;
      display: flex;
      align-items: center;
      background: white;
      box-shadow: 0 0 10px 0 rgb(49, 49, 49);
      @media (max-width: 768px) {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 0px;
      }
      .logo {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        margin-left: 5%;
        text-align: left;
        img {
          width: 25%;
          @media (max-width: 768px) {
            width: 40%;
          }
        }
      }
      .menus {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        text-align: center;
        @media (max-width: 768px) {
          flex-direction: column;
          background-color: #000000b0;
          justify-content: space-evenly;
          position: absolute;
          width: 100%;
          top: -230px;
          right: 0;
          text-align: center;
          padding-top: 0px;
          padding-bottom: 0px;
          //margin-right: 20px;
          border-radius: 0 0 10px 10px;
          z-index: -1;
        }
        &.active {
          top: 100%;
          margin-right: 0px;
          right: 0;
          transition: all 0.5s ease-in-out;
          z-index: -1;
        }
        ul {
          padding-inline-start: 0px;
          @media (max-width: 768px) {
            margin: 5px;
          }
        }
        ul li {
          display: inline-block;
          list-style-type: none;
          padding: 20px;
          text-align: center;
          @media (max-width: 768px) {
            padding: 10px;
            display: block;
          }
          &:hover {
            background-color: rgba(214, 214, 214, 0.57);
            border-radius: 5px;
          }
  
          .link {
            text-decoration: none;
            color: #555555;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            text-align: center;
            font-size: 18px;
            @media (max-width: 768px) {
              color: white;
            }
          }
        }
      }
      .icons {
        .bars {
          cursor: pointer;
          color: #292929;
          display: none;
          @media (max-width: 768px) {
            display: block;
            margin-right: 20px;
            font-size: 25px;
            &.active {
              display: none;
              margin: 0px;
              font-size: 0px;
              transition: all 0.5s ease-in-out;
            }
          }
        }
        .close {
          cursor: pointer;
          color: #181818;
          display: none;
          @media (max-width: 768px) {
            display: none;
            margin: 0px;
            font-size: 0px;
            &.active {
              display: block;
              margin-right: 20px;
              font-size: 25px;
              transition: all 2s ease-in-out;
            }
          }
        }
      }
    }
  }