.about-hiera {
    width: 100%;
    background-color: white;
    @media (max-width: 768px) {
        width: 100%;
    }

    .containerlogohiera {
        width: 100%;
        padding-top: 30px;
        display: flex;
        flex-direction: row;
        @media (max-width: 768px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .logohiera {
            width: 100%;
            text-align: end;
            justify-content: center;
            @media (max-width: 768px) {
                width: 100%;
                text-align: center;
            }
            img {
                width: 30%;
                padding-right: 30px;
                @media (max-width: 768px) {
                    width: 60%;
                    margin-left: 20px;
                }
            }
        }
        .logosinarmas {
            width: 100%;
            padding-top: 30px;
            text-align:left;
            justify-content: center;
            @media (max-width: 768px) {
                width: 100%;
                text-align: center;
            }
            img {
                width: 40%;
                @media (max-width: 768px) {
                    width: 60%;

                }
            }
        }
    }
    .containerdesk {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
            width: 100%;
        }
        .judul1 {
            width: 100%;
            justify-content: center;
            text-align: center;
            h1{
                font-family: "poppins" sans-serif;
                color: #a77a52;
                font-weight: 400;
                font-size: 40px;
                @media (max-width: 768px) {
                    width: 100%;
                    font-size: 20px;
                }
            }
        }
        .aboutsubtitle {
            margin-bottom: 25px;
            .read-more-button {
              margin-top: 10px;
              cursor: pointer;
              align-items: center;
              justify-content: center;
              text-align: center;
            }
          }
    }
}