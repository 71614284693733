.rumah{
    width: 100%;
    margin-top: 50px;
    @media (max-width: 768px){
        margin-top: 75px;
    }
    .logowelton {
        width: 100%;
        background-color:#2b2c35 ;
        padding-bottom: 20px;
        margin-bottom: 20px;
       display: flex;
       justify-content: center;
        @media (max-width: 768px){
            margin-top: -50px;
        }
          .gambarwelton {
            width: 25%;
            
            @media (max-width: 768px){
                width: 50%;
            }
            img {
                width: 30px;
            }
          }  
       
    }
    .contanierproduk1{
        width: 100%;
        @media (max-width: 768px){
            width: 100%;
        }
    .Judul{
        justify-content: center;
        margin-top: 1%;
        margin-bottom: 10px;
        font-size: 40px;
        font-family: 'Poppins' sans-serif;
        font-weight: 600;
        text-align: center;
        color: #1f565c;
        @media (max-width: 768px){
            //margin-top: -15%;
            font-size: 24px;
        }
    }
    .rumah-card{
        @media (max-width: 768px){
            margin-top: -15%;}
    }
}
}
