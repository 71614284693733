.judulpromo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 768px) {
      margin-top: -50px;
    }
    .judul {
      width: 100%;
      font-weight: 600;
      font-size: 30px;
      color: #de9a60;
      background-color: #2b2c35;
      @media (max-width: 768px) {
        text-align: center;
      }
      h1 {
        width: 100%;
        text-align: center;
        @media (max-width: 768px) {
          width: 100%;
          text-align: center;
          font-size: 50px;
        }
      }
    }
  }
  
  .containerPenawaran {
    margin-top: 30px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end; /* Added justify-content */
    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 0;
    }
    .containergambar {
      order: 1;
      width: 100%;
      text-align: right;
      padding-top: 20px;
      @media (max-width: 768px) {
        margin-top: 25px;
        order: 1;
        width: 100%;
        text-align: center;
      }
  
      .penawarangambar {
        width: 500px;
        height: 500px;
        align-self: flex-end;
        box-shadow: 0 0 10px 0;
        border-radius: 5px;
 
        @media (max-width: 768px) {
          width: 90%;
          height: 100%;
        }
      }
    }
  
    .containercontent {
      order: 2;
      width: 100%;
      margin-left: 100px;
      text-align: left;
      justify-content: center;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        margin-left: 25px;
        width: 90%;
        order: 2;
        margin-top: 15px;
      }
    }
    .disclaimer {
      margin-top: 25px;
      font-size: 13px;
      color: rgb(255, 255, 255);
    }
    .pointpenawaran {
      flex-direction: row;
      font-size: 30px;
      @media (max-width: 768px) {
        font-size: 25px;
        width: 100%;
      }
      .penawaranpoin {
        margin-top: 0px;
        color: #a77a52;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .buttonpenawaran {
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-top: 30px;
      margin-left: 15%;
      background-color: #a77a52;
      width: 50%;
      padding: 15px 30px;
      border: 1px solid #a77a52; /* Add this line for black border */
      border-color: #a77a52;
      border-radius: 25px;
      font-size: 16px;
      font-weight: light;
      cursor: pointer;
      display: inline-block;
      transition: background-color 0.3s ease, color 0.3s ease;
      @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
        margin-left: 15%;
        width: 70%;
      }
      &:hover {
        background-color: transparent;
        color: #a77a52;
        border: 1px solid #a77a52;
        border-color: #a77a52;
      }
      
    }
  }